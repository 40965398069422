import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FAQContainer, FAQs } from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>Flight Schools &amp; Instructors - QLD Sports Aviators</title>
      <meta
        name="description"
        content="Get in touch with one of our professional and experienced flight instructors and tandem pilots. Find your local instructor today."
      />
    </Helmet>

    <HomeHeader slant={false}>
      <figure>
        <Img fluid={data.wing.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Flight Schools &amp; Instructors</h2>
        <Text size="large">Learn to fly or find a tandem.</Text>
      </div>
    </HomeHeader>

    <FAQContainer style={{ marginTop: -65 }}>
      <FAQs>
        <h2 style={{ marginBottom: 20 }}>Learning &amp; Tandem Experiences</h2>
        <p style={{ fontSize: '1.3rem', marginBottom: 80 }}>
          <i>
            Want to learn, or looking for a tandem? Check out some of
            Queensland's best schools and instructors below. Begin the journey
            of learning to fly, or book in for a tandem experience today!
          </i>
        </p>

        <h4 style={{ color: '#c12422' }}>
          North Side Paragliding - CFI Diogo Coradini Paragliding and Paramotoring School
        </h4>
        <p>
          We are based in Sunshine Coast and we fly from Rainbow Beach to
          Mooloolaba and inland sites from Gympie area to Glass House
          Mountains, but operate in many locations across Australia. See our
          events, or contact us to book by request.
        </p>
        <a href="https://www.northsideparagliding.com.au/" target="_blank">
          https://www.northsideparagliding.com.au/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          SEQ Paragliding - CFI Joshie Tie
        </h4>
        <p>
          A paragliding school nestled in the picturesque Sunshine Coast region of
          Australia, flying inland and coastal sites on the Sunshine Coast from
          Mooloolaba to Rainbow Beach. Joshi offers several courses for complete
          novices as well as progression courses and endorsements. Check Joshi's
          website or contact him to get more information.
        </p>
        <a href="http://www.seqparagliding.com/" target="_blank">
          http://www.seqparagliding.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Canungra/Gold Coast - CFI Lisa Bradley
        </h4>
        <p>
          Lisa and her team recognize and appreciate that every student has a
          different background, wants and needs. Training is only progressed at
          an individual’s pace, in incremental steps so they quickly gain
          mastery and feel they’ve achieved something significant from the very
          early stages of their course.
        </p>
        <a href="http://www.ozparagliding.com/" target="_blank">
          http://www.ozparagliding.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Canungra/Gold Coast - CFI Philip Hystek
        </h4>
        <p>
          Our "Chief Flying Instructor" is Phil Hystek. Phil is one of the most
          experienced and knowledgable instructors in Australia with more than
          27 years of "free flight" instruction to his credit. Phil has taught
          in places as diverse as Australia, Indonesia, India, Iceland, USA,
          Europe and New Zealand, and is ready to take you to new heights in
          your quest for soaring freedom. Phil and his team of experienced
          instructors look forward to helping you to get airborne and find "true
          flying freedom"
        </p>
        <a href="https://www.paraglidingqueensland.com/" target="_blank">
          https://www.paraglidingqueensland.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Canungra/Gold Coast - CFI Jason Turner
        </h4>
        <p>
          Paratech's Chief Flight Instructor, Jason Turner, has over 29 years
          flying experience with paragliders, hang gliders, sailplanes and
          ultralight aircraft. A full time Instructor for over 15 years, Jason
          is well known for his calm, comprehensive and adaptive teaching style
          with a high regard for student safety. Jason is still a formidable and
          well respected pilot with recent podium finishes in competitions,
          several long distance record flights and has much more to offer our
          students than most entry level instructors, thanks to his extensive
          and proven flying background.
        </p>
        <a href="https://www.paratechpg.com/" target="_blank">
          https://www.paratechpg.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Toowoomba Paragliding and Paramotoring flight - CFI Matthew Fox</h4>
        <p>
          Tribal Flight Paragliding is a registered SAFA flying school and
          training facility. All schools are required to conform to the SAFA
          (and CASA) minimum standards for equipment, operational systems,
          certification and ongoing support. The school is run by Matt Fox who
          is the Chief Flight Instructor (CFI) and also a World Record holder
          for Paramotoring. As part of the training and courses, Tribal Flight
          Paragliding will introduce you to other pilots and local paragliding
          clubs and groups. Your learning is ongoing and sharing your
          experiences with other pilots is key to your success.{' '}
        </p>
        <a href="http://www.tribalflight.com.au/" target="_blank">
          http://www.tribalflight.com.au/
        </a>
        <p></p>
      </FAQs>
    </FAQContainer>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Complete the enquiry form and contact us today to find out how we can
        help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>
  </Layout>
)

export const query = graphql`
  query InstructorsQuery {
    paragliding: file(relativePath: { eq: "paragliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hanggliding: file(relativePath: { eq: "hang-gliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    microlighting: file(relativePath: { eq: "microlighting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wing: file(relativePath: { eq: "instructors-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/carports/gable/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(
      relativePath: { eq: "gallery/carports/skillion/cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "gallery/carports/flyover/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
